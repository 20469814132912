import { MenuItemDef } from 'ag-grid-community';
import { catchError, of } from 'rxjs';
import { AnalyticsSandboxStatus } from 'src/app/models/analyticsSandboxStatus.enum';
import {
  ADD_TO_GRAPHBASKET_ICON,
  EXPORT_TO_ANALYTICS_ICON,
  VALUATION_COCKPIT_ICON,
} from 'src/app/models/contextMenuIcons.model';
import {
  DataObjectTypeName,
  DataObjectTypes,
} from 'src/app/models/dataObject.model';
import { ExportToAnalyticalSandboxModel } from 'src/app/models/exportToAnalyticalSandbox.model';
import { GraphBasketItem } from 'src/app/models/graphBasket.model';
import { TimeSeriesRevision } from 'src/app/models/timeSeriesRevision.model';
import { ApiService } from '../../shared/services/api.service';
import { GraphBasketService } from '../../shared/services/graphBasket.service';
import { NavService } from '../../shared/services/nav.service';
import { NotificationService } from '../../shared/services/notification.service';
import { Granularity } from 'src/app/models/granularity.enum';

export function isEmptyNullOrUndefined(value: unknown) {
  return value === '' || value === null || value === undefined;
}

export function emptyIfNullOrUndefined(
  value: string | null | undefined
): string {
  return value === null || value === undefined ? '' : value;
}

export function getValueIfNotEmptyNullUndefined(
  value: string | null | undefined
): string | null {
  return isEmptyNullOrUndefined(value) ? null : (value as string);
}

export function getNullOrValue<T = unknown>(value: T): NonNullable<T> | null {
  if (value !== null && value !== undefined) {
    return value as NonNullable<T>;
  }
  return null;
}

export function getValue<T = unknown>(value: T): NonNullable<T> {
  if (value !== null && value !== undefined) {
    return value as NonNullable<T>;
  }

  throw new Error(`Value passed to getValue cannot be null`);
}

export function parseToNumberOrNull(value: any) {
  return !isEmptyNullOrUndefined(value) ? Number(value) : (null as any);
}

export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

export function assertNotNullable<T>(
  value: T
): asserts value is NonNullable<T> {
  if (!isNonNullable(value)) {
    throw new Error(`value ${value} is nullable`);
  }
}

export function assertDataObjectType<T extends DataObjectTypes>(
  value?: DataObjectTypes | null
): asserts value is T {
  const matchingObjectType = Object.values(DataObjectTypeName).find(
    (dataObjectType: string) => value?.objectType === dataObjectType
  );

  if (!matchingObjectType) {
    throw new Error(`value ${value} is not a DataObjectType`);
  }
}

export function getTodaysDate() {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
}

export function toRMVDDateString(d: Date) {
  // returns: yyyy-MM-dd
  return `${d.toLocaleString('default', {
    year: 'numeric',
  })}-${d.toLocaleString('default', { month: '2-digit' })}-${d.toLocaleString(
    'default',
    { day: '2-digit' }
  )}`;
}

export function toRMVDDateTimeString(d: Date) {
  // returns: yyyy-MM-dd HH:mm:ss +HH:mm
  return (
    d.getFullYear().toString() +
    '-' +
    ((d.getMonth() + 1).toString().length == 2
      ? (d.getMonth() + 1).toString()
      : '0' + (d.getMonth() + 1).toString()) +
    '-' +
    (d.getDate().toString().length == 2
      ? d.getDate().toString()
      : '0' + d.getDate().toString()) +
    ' ' +
    (d.getHours().toString().length == 2
      ? d.getHours().toString()
      : '0' + d.getHours().toString()) +
    ':' +
    (d.getMinutes().toString().length == 2
      ? d.getMinutes().toString()
      : '0' + d.getMinutes().toString()) +
    ':' +
    (d.getSeconds().toString().length == 2
      ? d.getSeconds().toString()
      : '0' + d.getSeconds().toString()) +
    formatTimezoneOffset(d)
  );
}

// Get the formatted timezone (UTC) offset of the locale of the machine the browser is running on.
// Example (for South Africa): +02:00
export function formatTimezoneOffset(d: Date) {
  var tzo = -d.getTimezoneOffset(),
    dif = tzo >= 0 ? ' +' : ' -',
    pad = function (num: number) {
      return (num < 10 ? '0' : '') + num;
    };
  return (
    dif + pad(Math.floor(Math.abs(tzo) / 60)) + ':' + pad(Math.abs(tzo) % 60)
  );
}

export function showAnalyticsSandbox(status: (string | null)[]): boolean {
  return (
    status.filter(
      (s) =>
        s !== AnalyticsSandboxStatus.Completed &&
        s !== AnalyticsSandboxStatus.InProgress
    ).length > 0
  );
}

export function buildAddToGraphBasketContextMenuItem(
  graphBasket: GraphBasketService,
  canShowMenuItem: boolean,
  items: {
    id: string;
    revisionId: number;
    name: string;
    timezone: string;
    unitOfMeasurement: string;
    granularity: string;
    timeSeriesName?: string;
  }[],
  itemsAsAGroup: Array<
    {
      id: string;
      revisionId: number;
      name: string;
      timezone: string;
      unitOfMeasurement: string;
      granularity: string;
      timeSeriesName?: string;
    }[]
  > = []
): MenuItemDef {
  return {
    name: 'Add to Graph Basket',
    disabled: (!(items.length || itemsAsAGroup.length) ? true : false) || !canShowMenuItem,
    action: () => {
      items.forEach((i) => {
        graphBasket.addToBasket({
          id: i.id,
          revisionId: i.revisionId,
          name: i.name,
          timezone: i.timezone,
          unitOfMeasurement: i.unitOfMeasurement,
          granularity: i.granularity,
          timeSeriesName: i.timeSeriesName
        });
      });
      itemsAsAGroup.forEach((groupedItem) => {
        graphBasket.addToBasketAsAGroup(groupedItem);
      });
    },
    icon: ADD_TO_GRAPHBASKET_ICON,
  };
}

export function buildExportToAnalyticsSandboxContextMenuItem(
  api: ApiService,
  canShowMenuItem: Boolean,
  messageService: NotificationService,
  isAlreadyInSandbox: boolean,
  items: ExportToAnalyticalSandboxModel[]
): any {
  const successMsg =
    'The export to Analytics Sandbox was started successfully.';
  const errorMsg = 'There was an error exporting to Analytics Sandbox.';
  return {
    name: `${isAlreadyInSandbox ? 'Update' : 'Export to'} Analytics Sandbox`,
    disabled: !canShowMenuItem || (!items.length ? true : false),
    action: () =>
      api
        .StartExportToAnalyticalSandbox(items)
        .pipe(
          catchError(() => {
            messageService.error(errorMsg);
            return of(null);
          })
        )
        .subscribe((r) => {
          if (
            r &&
            r.migrationStatusResponse &&
            r.migrationStatusResponse.length > 0 &&
            r.migrationStatusResponse[0].isSuccess
          ) {
            messageService.success(successMsg);
          } else {
            messageService.error(errorMsg);
          }
        }),
    icon: EXPORT_TO_ANALYTICS_ICON,
  };
}

export function buildNavigateToValuationCockpitContextMenuItem(
  navService: NavService,
  canShowMenuItem: boolean,
  dealTermId: string,
  valuationTaskId: string | null = null,
  valuationRunId: string | null
): any {
  return {
    name: 'Valuation Cockpit',
    action: () => {
      navService.goToValuationCockpit(
        dealTermId,
        valuationTaskId,
        valuationRunId
      );
    },
    disabled: !canShowMenuItem,
    icon: VALUATION_COCKPIT_ICON,
  };
}

export function isTimeSeriesInAnalyticsSandbox(rev: TimeSeriesRevision) {
  return isInAnalyticsSandbox(rev.analyticsSandboxStatus);
}

export function isInAnalyticsSandbox(status: string | null) {
  return status && status !== AnalyticsSandboxStatus.Purged;
}

export function timeSeriesAnalyticsSandboxIsProcessing(
  rev: TimeSeriesRevision
) {
  return analyticsSandboxIsProcessing(rev.analyticsSandboxStatus);
}
export function analyticsSandboxIsProcessing(status: string | null) {
  return status === AnalyticsSandboxStatus.InProgress;
}

export function timeSeriesAnalyticsSandboxSucceeded(rev: TimeSeriesRevision) {
  return analyticsSandboxSucceeded(rev.analyticsSandboxStatus);
}

export function analyticsSandboxSucceeded(status: string | null) {
  return status === AnalyticsSandboxStatus.Completed;
}

export function timeSeriesAnalyticsSandboxFailed(rev: TimeSeriesRevision) {
  return analyticsSandboxFailed(rev.analyticsSandboxStatus);
}

export function analyticsSandboxFailed(status: string | null) {
  return status === AnalyticsSandboxStatus.Error;
}

export function buildRevisionDescription(
  rev: TimeSeriesRevision | null
): string {
  return rev === null
    ? ''
    : `Revision ${rev.revisionId}${rev.labels.length > 0 ? ' - ' : ''
    }${rev.labels.join(', ')}`;
}

export const AgGridMenuItemSeparator: string = 'separator';

export function prefixIfNotAlreadyPrefixed(
  prefix: string,
  input: string
): string {
  // prefix the input string ONLY if it is NOT already prefixed with prefix value
  return input.startsWith(prefix) ? input : `${prefix}${input}`;
}

export function shouldRenderGraphAsSteps(granularity?: string | null) {
  return (
    granularity === Granularity.Monthly || granularity === Granularity.Yearly
  );
}

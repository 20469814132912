import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { LoggedOnUser } from '../../models/loggedonUser.model';

@Injectable({
  providedIn: 'root',
})
export class UniperAuthService {
  constructor(private msal: MsalService) { }

  public get IsLoggedIn() {
    return this.msal.instance.getAllAccounts().length > 0;
  }

  public get LoggedOnUser(): LoggedOnUser | null {
    if (this.IsLoggedIn) {
      const account = this.msal.instance.getAllAccounts()[0];
      return new LoggedOnUser(account.name ?? 'not specfied', account.username, account?.idTokenClaims?.roles);
    } else return null;
  }

  public get hasInventoryAccess(): any | null {
    if (this.IsLoggedIn) {
      const account: any = this.msal.instance.getAllAccounts()[0];
      if (account.idTokenClaims && account.idTokenClaims.roles) {
        const roles = account.idTokenClaims.roles.map((role: string) => role.toUpperCase()); // Convert each role to uppercase
        const isManager = roles.includes('MANAGER');
        return isManager;
      } else {
        return null
      }
    } else return null;
  }

  public get hasOnlyInventoryAccess(): any | null {
    if (this.IsLoggedIn) {
      const account: any = this.msal.instance.getAllAccounts()[0];
      if (account.idTokenClaims && account.idTokenClaims.roles) {
        const roles = account.idTokenClaims.roles.map((role: string) => role.toUpperCase()); // Convert each role to uppercase
        const hasOnlyManagerOrReader = roles.length === 1 && (roles[0] === 'MANAGER' || roles[0] === 'READER');
        return hasOnlyManagerOrReader;
      } else {
        return null
      }
    } else return null;
  }

  public get hasOnlyRMVDAccess(): any | null {
    if (this.IsLoggedIn) {
      const account: any = this.msal.instance.getAllAccounts()[0];
      if (account.idTokenClaims && account.idTokenClaims.roles) {
        const roles = account.idTokenClaims.roles.map((role: string) => role.toUpperCase()); // Convert each role to uppercase
        const hasOnlyAdminOrUser = roles.length === 1 && (roles[0] === 'ADMIN' || roles[0] === 'USER');
        return hasOnlyAdminOrUser;
      } else {
        return null
      }
    } else return null;
  }

  public get hasRMVDAccess(): any | null {
    if (this.IsLoggedIn) {
      const account: any = this.msal.instance.getAllAccounts()[0];
      if (account.idTokenClaims && account.idTokenClaims.roles) {
        const roles = account.idTokenClaims.roles.map((role: string) => role.toUpperCase()); // Convert each role to uppercase
        const isAdmin = roles.includes('ADMIN');
        return isAdmin;
      } else {
        return null
      }
    } else return null;
  }

  public get hasReaderAccess(): any | null {
    if (this.IsLoggedIn) {
      const account: any = this.msal.instance.getAllAccounts()[0];
      const roles = account.idTokenClaims.roles.map((role: string) => role.toUpperCase()); // Convert each role to uppercase
      return roles.includes['READER'];
    } else return null;
  }
}

<div class="flex flex-col h-screen min-h-full overflow-y-auto bg-gray-300">
  <nav class="bg-uniper">
    <div class="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <a routerLink="/">
              <img class="h-16 w-16" src="/assets/uniper_logo.png" alt="Workflow">
            </a>
          </div>
          <div class="hidden md:block ml-10 text-white text-3xl font-bold"><a routerLink="/">RMVD</a></div>
          <div class="hidden md:block">
            <div class="ml-10 flex items-baseline space-x-4">
              <!-- menu item would go here -->
              <a *ngIf="!hasOnlyInventoryAccess()" routerLink="dashboard" routerLinkActive="bg-blue-700" class="text-white hover:bg-blue-500 hover:bg-opacity-75 py-2 px-1 rounded-md text-sm font-medium self-center" aria-current="page">Personal Dashboard</a>           
              <a *ngIf="!hasOnlyInventoryAccess()" routerLink="data-management" routerLinkActive="bg-blue-700"        class="text-white hover:bg-blue-500 hover:bg-opacity-75 py-2 px-1 rounded-md text-sm font-medium" aria-current="page">Data Management</a>
              <a *ngIf="!hasOnlyInventoryAccess()" routerLink="add-model-caliberation" routerLinkActive="bg-blue-700" class="text-white hover:bg-blue-500 hover:bg-opacity-75 py-2 px-1 rounded-md text-sm font-medium">Model Calibration</a>           
              <a *ngIf="!hasOnlyInventoryAccess()" routerLink="background-jobs" routerLinkActive="bg-blue-700"        class="text-white hover:bg-blue-500 hover:bg-opacity-75 py-2 px-1 rounded-md text-sm font-medium">Background Jobs</a>           
              <app-action-button-with-dropdown *ngIf="!hasOnlyInventoryAccess()" [viewModel]="actionButtonViewModel$ | async"></app-action-button-with-dropdown>
              <a *ngIf="!hasOnlyRMVDAccess()" routerLink="ppa-inventory" routerLinkActive="bg-blue-700" class="text-white hover:bg-blue-500 hover:bg-opacity-75 py-2 px-1 rounded-md text-sm font-medium">PPA Inventory</a>
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <div class="ml-3 relative">
              <div class="flex">
                <app-logged-on-user [loggedInText]="loginDetails"></app-logged-on-user>
                <button type="button" (click)="appInfo()" class="ml-2 cursor-pointer text-white" title="Application Info">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>   
  </nav>
  <app-header></app-header>  
  <main class="flex-grow">
    <div class="max-w-8xl mx-auto sm:px-4 h-full">
      <div class="px-4 py-2 sm:px-0 h-full">
        <div class="rounded-lg h-full">
          <app-notification></app-notification>
          <app-loading></app-loading>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </main>
</div>
